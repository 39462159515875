@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');


*{
  font-family: 'Comfortaa', cursive;
}

.App {
  text-align: center;
}

.flexImg {
  display: block;
  max-width: 50%;
  height: auto;
  width: auto\9;
  margin-left: auto;
  margin-right: auto;
}

.projectCardImage {
  /* display: block; */
  /* max-width: 100%; */
  /* height: auto; */
  /* width: auto\9; */
  /* height: 100%;
  overflow: hidden; */
}

section {
  margin-top: 25vh;
  height: 100vh;
  /* position: relative; */
  /* margin-top:100vh; */
}
#Landing {
  margin-top: 0vh;
}

#About {
  /* height: 50vh; */
}

#Projects {
  margin-top: 30vh;
  height: 70vh;
}

#Contact {
  /* margin-top: 25vh; */
  height: 50vh;
}

.glassPanel {
  background-color: rgba(255, 255, 255, .30);
  backdrop-filter: blur(5px);
}


@media only screen and (max-width: 767px) { 
  #Projects {
    /* margin-top:100%; */
    /* height: -300vh; */
  }

  /* .carouselSizing {
    width:auto;
    height:auto;
  } */


  #Contact {
    /* margin-top:100vh */
  }

}

.carouselSizing {
  /* width:50%; */
  /* height:50vh; */
  /* margin: auto; */
}
